/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

.ecommerce button.collapsed::after {
    content: "\f0415";
    top: 50%;
}

.ecommerce button::after {
    content: "\f0374";
    display: block;
    font-family: "Material Design Icons";
    font-size: 16px;
    position: absolute;
    right: 20px;
    font-weight: 500;
    top: 30%;
    background-image: none;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.accordion-button:not(.collapsed)::after {
    background-image: none;
}

*, body{
  font-family: "Nunito", sans-serif !important;
  font-optical-sizing: auto !important;
  font-style: normal !important;
}

.fab, .fas, .far, .fal, .fad {
  font-family: "Font Awesome 5 Brands" !important;
}


.dropdown-toggle::after {
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}


swal2-container.swal2-container-position {
  position: absolute;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 99999 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  overflow: auto !important;
}
.swal2-container.swal2-container-position > .swal2-modal {
  background-color: transparent;
  border: 0px;
}
body.swal2-no-backdrop .swal2-container.swal2-container-position>.swal2-modal{
  box-shadow: none;
}
.swal2-popup-custom-style {
  color: white;
}
.cursor{
  cursor: pointer;
}
